import { useState, useEffect } from 'react'
import { IoIosClose } from 'react-icons/io'

const Membership = () => {

    const [memLink, setMemLink] = useState('');
    const [plusLink, setPlusLink] = useState('');

    let url = window.location;
    let params = new URLSearchParams(url.search);
    const [paySuccess, setPaySuccess]  = useState(params.get('success'));


    useEffect(()=> {
        fetch('/sm1/checkout-member.php')
          .then((response) => response.text())
          .then((data) => setMemLink(data));

          fetch('/sm1/checkout-plus.php')
          .then((response) => response.text())
          .then((data) => setPlusLink(data));
    },[])

    return (
      <>
        { paySuccess &&
        <>
        <div className='fixedback' onClick={()=>setPaySuccess(false)}></div>
        <div className='waitingpop'>
            <IoIosClose size={40} style={{color:'#797979', position:'absolute', top: '10px', right:'10px', cursor:'pointer'}} onClick={()=>setPaySuccess(false)}  />

            <div className='success-msg'>
                    <p>Thank you for becoming a member! Look out for your confirmation email. If you don't receive one in the next 10 minutes, please contact us at hello@write-and-shine.com.</p>
                    <a href='https://write-and-shine.com'><button className='btncenter book nomartop'>Back to homepage</button></a>
                </div>
        </div>
        </>
        
        
        }
      
        <div className='banner'></div>
        <section className='line'>
            <div className='intro'>
            <h2>Membership</h2>
                <p className='opening'>Make the most of your mornings!</p>
                <p>Joining as a member allows you to enjoy Write & Shine's workshops from £99 per month. Think of it as a class-pass, with added support, community and inspiration.</p>
                <p>For those who want added joy, we've created a membership that includes advanced booking, limited-edition seasonal A4 giclée prints as well as access to our 'Writing with the Seasons' monthly essays. Our Membership+ is £149 per month.</p>
                <p>All our events take place online via Zoom (UK time), unless otherwise stated. We look forward to welcoming you along!</p>
            </div>
        </section>
        <section className='km'>
        <h3 className='center'>Members enjoy access to:</h3>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-0'></div>
            </div>
            <div className='right'>
            <p>Our seasonal programme of workshops, guest tutor sessions and morning writing retreats. See our current season <a href='https://booking.write-and-shine.com' className='link'>here</a>.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-1'></div>
            </div>
            <div className='right'>
            <p>Two seasonal salons in May and  November (worth £199 each), offering a week of bright and brilliant events. Plus, the December and Summer Festivals of creativity and imagination. View our latest salon programme <a href='https://salon.write-and-shine.com' className='link'>here</a>.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-2'></div>
            </div>
            <div className='right'>
            <p>Write & Shine's <a href='https://write-and-shine.com/online/' className='link'>online library</a> of courses, featuring over 100 inspiring themes to spark your creativity allow new ideas to flow.</p>
            </div>
        </div>
      
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-3'></div>
            </div>
            <div className='right'>
            <p>Special treats and exclusive member-only events along the way!</p>
            </div>
        </div>

        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img img-4'></div>
            </div>
            <div className='right'>
            <p>Our Membership+ also includes advanced booking, our specially commissioned A4 giclée prints and access to our 'Writing with the Seasons' monthly essays.</p>
            </div>
        </div>
        <p className='center'><a href='https://write-and-shine.com/membership-terms-conditions/' className='link'>Read our full terms and conditions here.</a></p>
        </section>



        <div className='item-wrap'>
            <div className='left item-img0'></div>
            <div className='right'>
            <h4>Membership</h4>
            <p className='sub'>£99 per month (cancel anytime)</p>
            <p className='blurb'>Our Memberships gives you access to all events in our core programme, including workshops, guest tutor sessions and morning writing retreats, as well as our online library.</p>
            <a href={`${memLink}`}>
                <button className='book btnright'>Join now</button>
            </a>
            </div>
      </div>



        <div className='item-wrap'>
            <div className='left item-img1'></div>
            <div className='right gold-back'>
            <h4 className='plus'>Membership</h4>
            <p className='sub'>£149 per month (cancel anytime)</p>
            <p className='blurb'>Our Membership+ gives you access to everything in our membership offer, alongside the added joy of priority booking, specially commissioned A4 giclée prints and access to our monthly essays series, 'Writing with the Seasons'.</p> 
            <a href={`${plusLink}`}>
                <button className='book btnright'>Join now</button>
            </a>
            </div>
          </div>

        <div className='extra-block'></div>


      </>
    )
}

export default Membership